<template>
  <div>
    <v-row align="center" justify="center">
      <v-col style="margin-top: 15%" cols="12">
        <v-progress-circular
          :size="130"
          :width="8"
          color="#1976d2"
          indeterminate
        ></v-progress-circular>
        <p class="mt-5">{{ $translate("general.loading") }} </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import alert from "@/mixins/alert";
import Api from "@/utils/api";
import moment from "moment";

export default {
  data() {
    return {
      infoToken: {
        document: "",
      },
    };
  },
  methods: {
    async validateToken() {
      try {
        const token = this.$route.query.token;
        
        if (!token) {
          console.error('No se encontró token en la URL');
          this.$router.push({ name: "unauthorized" });
          return;
        }

        const response = await Api.noAuth().login(token);
        
        if (response.data.cod === 0 && response.data.data === 0) {
          // Guardar tokens
          localStorage.setItem("token", atob(token));
          localStorage.setItem("tokenB64", token);
          
          // Verificar que el token se guardó correctamente
          const savedToken = localStorage.getItem("token");
          const savedTokenB64 = localStorage.getItem("tokenB64");
          
          if (savedToken && savedTokenB64) {
            // Obtener información del usuario antes de navegar
            try {
              const userResponse = await Api.Auth().currentUser(savedTokenB64);
              if (userResponse.data.cod === 0 && userResponse.data.data) {
                // Guardar datos del usuario
                localStorage.setItem("userData", JSON.stringify(userResponse.data));
                this.$store.dispatch("updateUserInfo", userResponse.data);
                this.saveLog();
                // Navegar al home solo después de tener los datos del usuario
                this.$router.push({ name: "home" });
              } else {
                throw new Error('Respuesta inválida al obtener datos del usuario');
              }
            } catch (userError) {
              console.error('Error al obtener datos del usuario:', userError);
              this.$router.push({ name: "unauthorized" });
            }
          } else {
            console.error('Error al guardar tokens en localStorage');
            this.$router.push({ name: "unauthorized" });
          }
        } else {
          console.error('Respuesta inválida del servidor:', response.data);
          this.$router.push({ name: "unauthorized" });
        }
      } catch (error) {
        console.error("Error en validación de token:", error);
        this.$router.push({ name: "unauthorized" });
      }
    },
    getToken() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error('No hay token en localStorage');
          return;
        }

        const div = token.split(".");
        if (div.length >= 2) {
          this.infoToken = JSON.parse(atob(div[1]));
        } else {
          console.error('Token malformado');
          this.infoToken = { document: "" };
        }
      } catch (error) {
        console.error('Error al procesar token:', error);
        this.infoToken = { document: "" };
      }
    },
    saveLog() {
      this.getToken();
      let dispositivo;
      if (screen.width < 1024) {
        dispositivo = "Mobile";
      } else {
        dispositivo = "Desktop";
      }
      const record = {
        userid: this.infoToken.document,
        appid: "79",
        appname: "Login Okan",
        date: moment().format("YYYY-MM-DD"),
        dispositivo,
      };
    },
  },
  mounted() {
    this.validateToken();
  },
};
</script>
